/* body {
  margin: 0;
    font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
}

body h1,h2,h3{
	font-weight: 600;
} */
/* body {
  overflow: hidden;
} */
body{
  overflow-x: hidden;
}

h1 {
  user-select: none;
  cursor: default;
}

h3{
  font-weight: 700;
  font-size: 24px;
}
h4{
  font-weight:400;
  font-size: 17px;
}
span{
  font-size: 17px;
}
@media only screen and (max-width: 768px) {
  h3{
    font-size: 22px;
  }
  span{
    font-size: 14px;
  }
}
input[type=text],input[type=number],input[type=email],input[type=password],input[type=text]:focus,input[type=number]:focus,input[type=email]:focus,input[type=password]:focus  {
  background-color: rgb(226, 225, 225);
  border: 0;
  outline: none;
  /* padding: 5% 4%; */
}
